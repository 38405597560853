/* globals Swiper, google */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Wecustom = {
    // All pages
    'common': {
      init: function () {
        $('.top-header').sticky({topSpacing: 0})
        // for example, remove if Swiper isn't used in this project
        function initSwiper () {
          return new Swiper('.swiper-header', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            loop: true,
            autoplay: 8000,
            slidesPerView: 1
          })
        }

        initSwiper()
        // end swiper example
        var titleHeight = $('.header-text').height()
        var newHeight = 405 - titleHeight + 'px'
        $('.header-text').css('top', newHeight)
        $('footer h4').matchHeight()
        $('.contact-contact h4').matchHeight()
        $('.home-dienst h4').matchHeight()
        $('.home-dienst').on('click', function () {
          var dienstUrl = $(this).find('a').attr('href')
          window.location.href = dienstUrl
        })
        function newMap ($el) {
          // var
          var $markers = $el.find('.marker')
          // vars
          var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
          }
          // create map
          var map = new google.maps.Map($el[0], args)
          // add a markers reference
          map.markers = []
          // add markers
          $markers.each(function () {
            addMarker($(this), map)
          })
          // center map
          centerMap(map)
          // return
          return map
        }

        function addMarker ($marker, map) {
          // var
          var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'))
          // create marker
          var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/app/themes/wecustom/dist/images/marker.png'
          })
          // add to array
          map.markers.push(marker)
          // if marker contains HTML, add it to an infoWindow
          if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content: $marker.html()
            })
            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {
              infowindow.open(map, marker)
            })
          }
        }

        /*
         *  centerMap
         *
         *  This function will center the map, showing all markers attached to this map
         *
         *  @type	function
         *  @date	8/11/2013
         *  @since	4.3.0
         *
         *  @param	map (Google Map object)
         *  @return	n/a
         */
        function centerMap (map) {
          // vars
          var bounds = new google.maps.LatLngBounds()
          // loop through all markers and create bounds
          $.each(map.markers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng())
            bounds.extend(latlng)
          })
          // only 1 marker?
          if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter())
            map.setZoom(16)
          } else {
            // fit to bounds
            map.fitBounds(bounds)
          }
        }

        /*
         *  document ready
         *
         *  This function will render each map when the document is ready (page has loaded)
         *
         *  @type	function
         *  @date	8/11/2013
         *  @since	5.0.0
         *
         *  @param	n/a
         *  @return	n/a
         */
        // global var
        $(document).ready(function () {
          $('.acf-map').each(function () {
            // create map
            newMap($(this))
          })
        })
        $(document).ready(function () {
          $('.css-transitions-only-after-page-load').each(function (index, element) {
            setTimeout(function () {
              $(element).removeClass('css-transitions-only-after-page-load')
            }, 10)
          })
        })
        $('#nav-icon').click(function () {
          $(this).toggleClass('open')
          $('nav.nav-mobile ul').slideToggle()
        })
        $('.orange').on('click', function () {
          window.location.href = '/contact'
        })

        var template = $('#sections .section:first').clone()

        var sectionsCount = 1

        $('.addsection').on('click', function () {
          sectionsCount++
          template.clone().find(':input').each(function () {
            var newId = this.id + sectionsCount
            $(this).prev().attr('for', newId)
            this.id = newId
          }).end()
            .appendTo('#sections')

          $('.removesection').on('click', function () {
            $(this).closest('.section').fadeOut(300, function () {
              return false
            })
            return false
          })
          return false
        })

        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_page': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  }

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire
      var namespace = Wecustom
      funcname = (funcname === undefined) ? 'init' : funcname
      fire = func !== ''
      fire = fire && namespace[func]
      fire = fire && typeof namespace[func][funcname] === 'function'

      if (fire) {
        namespace[func][funcname](args)
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common')

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm)
        UTIL.fire(classnm, 'finalize')
      })

      // Fire common finalize JS
      UTIL.fire('common', 'finalize')
    }
  }

  // Load Events
  $(document).ready(UTIL.loadEvents)
})(jQuery) // Fully reference jQuery after this point.
